"use client";

import { useEffect, useRef } from "react";
import Hls from 'hls.js';

function VideoBG() {

  const videoRef = useRef<HTMLVideoElement>(null);
  const src = "https://customer-zbfj68pih6xr801a.cloudflarestream.com/ebac1e828275a176632f70451c52cd6d/manifest/video.m3u8";

  useEffect(() => {
    const video = videoRef.current;

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(src);
      // @ts-ignore
      hls.attachMedia(video);
      hls.on(Hls.Events.ERROR, (_, data) => {
        console.error('HLS.js error:', data);
      });
    } else if (video && video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = src;
    } else {
      console.error('HLS not supported in this browser.');
    }

    return () => {
      if (video) {
        video.src = '';
      }
    };
  }, [src]);
  return (
    <video
      ref={videoRef}
      autoPlay
      loop
      muted
      playsInline
      className="w-full h-full object-cover bg-black"
    />

  );
}

export default VideoBG;
